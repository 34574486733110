
.main_container {
    position: relative;
    width: 100%;
    background-image: url('/public/cover.jpg');
    background-size: cover;
    background-color: #484848d1; /* Fallback color in case the image is not loaded or transparent */
    background-blend-mode: overlay; /* Blend the background image with the color */
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}

.text {
    font-size: 22px;
    color: #ffffff;
}

.header-contact-details {
    width: 500px;
}

.services {
    margin-top: 10px;
}

@media (max-width: 1120px) {
    .header-contact-details {
        margin-top: -15px;
        width: 250px;
    }

    .services {
        margin-top: 100px;
    }
}

@media (max-width: 991px) {

    h5, span, a {
        font-size: 14px;
    }

    img.logo {
        width: 100px;
        margin-top: 20px;
    }

    #navbar {
        height: 250px;
        overflow-y: scroll;
        text-align: right;
        background-color: rgb(14 4 15 / 78%);
    }


}




