

.about-us-content {
    max-width: 800px;
    margin: 0 auto;
}


.about-us-content h2 {
    /*font-size: 24px;*/
    color: #333;
}

.about-us-content h3 {
    font-size: 20px;
    color: #444;
}

.about-us-content p {
    font-size: 18px;
    color: #666;
}

.about-us-content ul {
    /*list-style-type: none;*/
    /*padding: 0;*/
}

.about-us-content ul li {
    margin-bottom: 10px;
}

.about-us-content p, .about-us-content ul {
    margin-bottom: 20px;
}
