.header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding-right: 20px;
}

.logo {
    width: 260px;
    font-size: 24px;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
}
.logo img {
    max-height: 90px;
    margin-left: 6px;
}

.logo i {
    font-size: 28px;
    margin-left: 5px;
    color: #6c757d;
}


.profile-img{
    max-width: 36px;
    margin-left: 5px;
}

.sidebar {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0 0 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
}
.sidebar.show {
    right: 0px;
}
.main {
    transition: margin-right 0.3s;
}


.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

@media (min-width: 767px) {

    #main,
    #footer {
        margin-right: 300px;
    }
}


.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-item {
    margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
    font-size: 11px;
    text-transform: uppercase;
    color: #899bbd;
    font-weight: 600;
    margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #4154f1;
    transition: 0.3s;
    background: #f6f9ff;
    padding: 10px 15px;
    border-radius: 4px;
}

.sidebar-nav .nav-link i {
    font-size: 16px;
    margin-left: 10px;
    color: #4154f1;
}

.sidebar-nav .nav-link.collapsed {
    color: #012970;
    background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
}

.sidebar-nav .nav-link:hover {
    color: #4154f1;
    background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
    color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
    margin-left: 0;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-content a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #012970;
    padding: 10px 40px 10px 0;
    transition: 0.3s;
}

.sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-left: 8px;
    line-height: 0;
    border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
    color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
    background-color: #4154f1;
}
.sidebar-responsive {
    display: inline !important;
    position: absolute;
    z-index: 12 !important;
}