
.shining:hover {
    background-color: #fbcd68;
    color: #000;
    box-shadow: 0 0 10px #fbcd68, 0 0 40px #fbcd68, 0 0 80px #fbcd68;
}

.fa-home {
    color: black;
    font-size: 35px;
}

.fa-user:hover, .fa-home:hover {
    color: #fbcd68;
    cursor: pointer;
}

.highlight:hover {
    color: #fbcd68;
}

.error {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
}

.success {

    color: green;
    font-size: 0.8em;
    margin-top: 5px;
}


.err_msg, .success_msg {
    font-size: 18px !important;
}
/* Style the scrollbar */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; /* Color of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the handle on hover */
}

button {
    background-color: #012970;
}

.modal-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reserved_modal .modal-content {
    width: 100% !important;
    max-width: 100% !important;
    direction: rtl;
}

.btn-close {
    margin:  0 !important;
}

.text-right {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;

}

/* styles.css */
.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.logo-container {
    margin-bottom: 20px; /* Adjust as needed */
}

.logo {
    width: 150px; /* Adjust the width of the logo */
    height: auto; /* Maintain aspect ratio */
}

.loading-indicator {
    text-align: center;
}

.loading-gif {
    width: 200px; /* Adjust the width of the loading indicator */
    height: auto; /* Maintain aspect ratio */
}


/* styles.css */

.transition-container {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.transition-container.show {
    opacity: 1;
}

.loading-container {
    transition: opacity 0.5s ease-in-out;
}

.loading-container:not(:empty) {
    opacity: 1;
}

.loading-container:empty {
    opacity: 0;
}
.bold {
    font-weight: bold;
}