.user-list-container {
    margin: 20px auto;
}

input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
}

.pagination li {
    margin-right: 5px;
}

.pagination button {
    cursor: pointer;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
}


td, th {
    text-align: center;
}


/* Modal */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
    max-width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Close Button */
.close {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.close:hover {
    color: #888;
}
