.dashboard-cards {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.dashboard-card {
    flex: 1;
    /*background-color: #fbcd68;*/
    /*color: #000;*/
    /*box-shadow: 0 0 10px #fbcd68, 0 0 40px #fbcd68, 0 0 80px #fbcd68;*/
    color: #dddddd;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-right: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 24px;
    color: #000000;
}

.card-description {
    font-size: 24px;
    color: #000000;
    margin-top: 10px;
}
